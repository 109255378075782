<!-- eslint-disable -->
<i18n>
{
  "de": {
    "title": "Titel",
    "subtitle": "Untertitel",
    "text":"Text",
    "moreinfo":"Bemerkungen",
    "categoryLabel": "Kategorie",
    "stateLabel": "Status",
    "emailhintEnd": "schicken wir Ihnen eine Email mit einem Aktivierungslink. Bitte geben Sie Ihre Email Adresse an:",
    "savedEnd": "Klicken Sie zur Freischaltung den Link in der Email an.",
    "files":"Bilder",
    "filesHint":"Halten Sie die CTRL/CMD Taste gedrückt um mehrere Bilder auszuwählen.",
    "comment": {
      "title": "@:title",
      "text":"@:text",
      "add": "Klicken Sie auf die Stelle in Karte, an der Sie einen Kommentar hinzufügen möchten.",
      "new": "Neuer Kommentar",
      "emailhint": "Um Ihren Kommentar freizuschalten, @:emailhintEnd",
      "saved": "Ihr Kommentar wurde gespeichert. @:savedEnd"
    },
    "polygon": {
      "title": "@:title",
      "text":"Beschrieb",
      "add": "Klicken Sie auf die Stelle in Karte, an der die erste Ecke der hinzuzufügenden Fläche sein soll.",
      "editing": {
        "invalid": "Ungültige Geometrie, hinzufügen dieses Punktes möglich.",
        "unfinished": "Klicken Sie erneut auf den Startpunkt, um die Fläche abzuschliessen",
        "closable": "Klicken Sie hier, um die Fläche abzuschliessen."
      },
      "new": "Neue Fläche",
      "emailhint": "Um Ihre Fläche freizuschalten, @:emailhintEnd",
      "saved": "Ihre Fläche wurde gespeichert. @:savedEnd"
    },
    "object": {
      "title": "Strasse / Nr.",
      "subtitle": "PLZ Ort",
      "text":"Architektur",
      "moreinfo":"Abrissgrund",
      "comment":"Kommentar",
      "constructionYear": "Baujahr",
      "demolitionYear": "Abrissjahr",
      "add": "Klicken Sie auf die Stelle in Karte, an der Sie ein Objekt hinzufügen möchten.",
      "new": "Neues Objekt",
      "emailhint": "Um Ihre Objekt freizuschalten, @:emailhintEnd",
      "saved": "Ihr Objekt wurde gespeichert. @:savedEnd",
      "constVal": {
        "na":"unbekannt",
        "v1":"ca. 1940er",
        "v2":"ca. 1950er",
        "v3":"ca. 1960er",
        "v4":"ca. 1970er",
        "v5":"ca. 1980er",
        "v6":"ca. 1990er",
        "v7":"ca. 2000er",
        "v8":"ca. 2010er",
        "v9":"ca. 2020er"
      },
      "demoVal": {
        "na":"unbekannt",
        "v1":"2020",
        "v2":"2021",
        "v3":"2022",
        "v4":"2023",
        "v5":"2024",
        "v6":"2025",
        "v7":"2026",
        "v8":"2027",
        "v9":"2028",
        "v10":"2029",
        "v11":"2030",
        "v12":"nach 2030"
      }
    },
    "PAR": {
      "categoryLabel": "@:categoryLabel",
      "stateLabel": "@:stateLabel",
      "comment": {
        "title": "@:comment.title",
        "text":"@:comment.text",
        "add": "@:comment.add",
        "new": "@:comment.new",
        "emailhint": "@:comment.emailhint",
        "saved": "@:comment.saved"
      },
      "polygon": {
        "title": "@:polygon.title",
        "text":"@:polygon.text",
        "add": "@:polygon.add",
        "editing": {
          "invalid": "@:polygon.editing.invalid",
          "unfinished": "@:polygon.editing.unfinished",
          "closable": "@:polygon.editing.closable"
        },
        "new": "@:polygon.new",
        "emailhint": "@:polygon.emailhint",
        "saved": "@:polygon.saved"
      },
      "object": {
        "title": "@:object.title",
        "subtitle": "@:object.subtitle",
        "text":"@:object.text",
        "moreinfo":"@:object.moreinfo",
        "comment":"@:object.comment",
        "constructionYear": "@:object.constructionYear",
        "demolitionYear": "@:object.demolitionYear",
        "add": "@:object.add",
        "new": "@:object.new",
        "emailhint": "@:object.emailhint",
        "saved": "@:object.saved"
      }
    },

    "MGT": {
      "categoryLabel": "Status",
      "stateLabel": "Gruppe",
      "comment": {
        "title": "@:comment.title",
        "text":"@:comment.text",
        "add": "Klicken Sie auf die Stelle in Karte, an der Sie eine Notiz hinzufügen möchten.",
        "new": "Neue Notiz",
        "emailhint": "Um Ihre Notiz freizuschalten, @:emailhintEnd",
        "saved": "Ihre Notiz wurde gespeichert. @:savedEnd"
      },
      "polygon": {
        "title": "@:polygon.title",
        "text":"@:polygon.text",
        "add": "@:polygon.add",
        "editing": {
          "invalid": "@:polygon.editing.invalid",
          "unfinished": "@:polygon.editing.unfinished",
          "closable": "@:polygon.editing.closable"
        },
        "new": "@:polygon.new",
        "emailhint": "@:polygon.emailhint",
        "saved": "@:polygon.saved"
      },
      "object": {
        "title": "@:object.title",
        "subtitle": "@:object.subtitle",
        "text":"@:object.text",
        "moreinfo":"@:object.moreinfo",
        "comment":"@:object.comment",
        "constructionYear": "@:object.constructionYear",
        "demolitionYear": "@:object.demolitionYear",
        "add": "@:object.add",
        "new": "@:object.new",
        "emailhint": "@:object.emailhint",
        "saved": "@:object.saved"
      }
    },
    "cancel": "abbrechen",
    "next": "weiter",
    "prev": "zurück",
    "save": "speichern",
    "saveinfo": "Speichere Angaben",
    "mandatory": "Dies ist ein Pflichtfeld",
    "email": "E-Mail",
    "inv": "Dies ist keine gültige E-Mail Adresse",
    "notpublic":"Diese Informationen werden nicht veröffentlicht oder an Dritte weitergegeben",
    "failed": "Speichern fehlgeschlagen",
    "failedText": "Bitte prüfen Sie Ihre Eingaben oder versuchen Sie es später nochmals.",
    "saved": "Speichern erfolgreich"
  },
  "fr": {
  }
}
</i18n>
<!-- eslint-enable -->

<template>
    <v-main :class="{navopen : snapshotnav}">
      <v-slide-x-reverse-transition>
        <v-btn fab absolute small
          style="top:13px; right:16px;"
          color="primary"
          v-if="!snapshotnav"
          @click="snapshotnav=!snapshotnav;">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </v-slide-x-reverse-transition>

      <v-container fluid class="pa-0" ref="mapbox">
        <span id="mapstatus" :class="{
          loaded: isMapLoaded,
          waiting: !isMapLoaded,
          addingAnnotation: addingAnnotation!==null
        }"></span>
        <!-- @mousemove="$refs.map.onMouseMove" -->
        <div id="map" :class="filterClasses"></div>
        <component
          ref="map"
          :is="mapType"
          :snapshot="snapshot"
          :annotations="annotations"
          :addingAnnotation="addingAnnotation"
          :newAnnotation="newAnnotation"
          @new-comment="newComment"
          @new-object="newObject"
          @new-polygon="newPolygon"
          @map-movestart="mapMovestart"
          @map-zoomed="mapZoomed"
          @map-moveend="mapMoveend"
          @zoomstate-changed="setZoomStateModified"
          @current-comment-changed="currentCommentChanged"
          >
        </component>
      </v-container>

      <div v-html="filterCatStyle"></div>
      <div v-html="filterStatStyle"></div>

      <v-slide-y-transition>
        <p class="addHint elevation-6" v-if="addingAnnotation">
          <span v-if="addingAnnotation == 'COM'">
             {{ c$t('comment.add') }}
          </span>
          <template v-else-if="addingAnnotation == 'PLY'">
            <span v-if="$refs.map.polygonEditingState.invalid">
              {{ c$t('polygon.editing.invalid') }}</span>
            <span v-else-if="$refs.map.polygonEditingState.closable">
              {{ c$t('polygon.editing.closable') }}</span>
            <span v-else-if="$refs.map.polygonEditingState.active">
              {{ c$t('polygon.editing.unfinished') }}</span>
            <span v-else>
              {{ c$t('polygon.add') }}</span>
          </template>
          <span v-else-if="addingAnnotation == 'OBJ'">
            {{ c$t('object.add') }}
          </span>
        </p>
      </v-slide-y-transition>

      <v-btn
        v-if="hash && !screenshotIsThumbnail"
        fab absolute small
        style="bottom:2.2em; right:1.3em;"
        :elevation="mapinfoopen ? 0 : 6"
        color="white"
        @click="mapinfoopen=!mapinfoopen">
        <v-icon>mdi-information-variant</v-icon>
      </v-btn>

      <v-card
        v-if="hash"
        id="mapinfo"
        class="px-4 py-2"
        :style="'width:' + legendWidth"
        v-bind:class="{open: mapinfoopen}"
        >
        <v-icon
          v-show="!screenshotMode"
          style="position: absolute; top:0; right:0;"
          class="pa-2"
          @click="mapinfoopen=!mapinfoopen">
          mdi-close-circle-outline
        </v-icon>
        <snapshot-meta
          :title="title"
          :description="description"
          :predecessor="predecessor"
          :hash="hash"
          :legend="legend"
          :legendCategories="legendCategories"
          :legendStates="legendStates"
          :sources="sources"
          v-on:toggleCat="(...args) => toggleCat(...args)"
          v-on:toggleState="(...args) => toggleState(...args)"
        />
      </v-card>

      <div id="buttons" v-if="!screenshotMode">

        <v-btn
          v-if="annotations.findme"
          fab small
          id="myLocation"
          color="primary"
          >
          <!-- TODO: reactivate location button
          @click="$refs.map.myLocation" -->
          <v-icon>mdi-crosshairs-gps</v-icon>
        </v-btn>

        <template v-if="wshash">

          <v-btn
            fab small
            id="addingAnnotationPt"
            color="primary"
            v-if="annotations.marker.open"
            @click="addingAnnotation ? addingAnnotation=null : addingAnnotation='COM';">
            <v-icon v-if="!addingAnnotation || addingAnnotation != 'COM'">
              mdi-comment-plus-outline
            </v-icon>
            <v-icon v-if="addingAnnotation && addingAnnotation == 'COM'">mdi-close-thick</v-icon>
          </v-btn>

          <v-btn
            fab small
            id="addingAnnotationPly"
            color="primary"
            v-if="annotations.polygon.open"
            @click="addingAnnotation ? addingAnnotation = null : addingAnnotation = 'PLY';">
            <v-icon v-if="!addingAnnotation || addingAnnotation != 'PLY'">
              mdi-shape-polygon-plus
            </v-icon>
            <v-icon v-if="addingAnnotation && addingAnnotation == 'PLY'">mdi-close-thick</v-icon>
          </v-btn>

          <v-btn
            fab small
            id="addingAnnotationObj"
            color="primary"
            v-if="annotations.object.open"
            @click="addingAnnotation ? addingAnnotation = null : addingAnnotation = 'OBJ';">
            <v-icon v-if="!addingAnnotation || addingAnnotation != 'OBJ'">
              mdi-home-city-outline
            </v-icon>
            <v-icon v-if="addingAnnotation && addingAnnotation == 'OBJ'">mdi-close-thick</v-icon>
          </v-btn>

          <v-btn
            fab small
            id="resetZoom"
            color="primary"
            v-if="zoomStateModified"
            @click="resetZoom">
            <v-icon>
              mdi-backup-restore
            </v-icon>
          </v-btn>

        </template>

      </div>

      <v-scale-transition origin="center">
        <div class="commentanimation" v-if="newAnnotation">
          <v-card
            id="commentedit"
            light width="400" class="pt-2 elevation-6"
          >
            <h3 class="py-2 px-4">
              <span>{{ c$t(annotationKindKey[newAnnotation.kind]+'.new') }}</span>
            </h3>
            <v-form
              class="pa-4 pt-0"
              ref="commentform"
              id="commentform"
              lazy-validation
              @submit.prevent="saveAnnotation"
            >
              <v-stepper v-model="commentstepper" class="elevation-0">
                <v-stepper-items>
                  <v-stepper-content step="1" class="pa-0">
                    <v-select
                      v-if="categoriesList"
                      :items="categoriesList"
                      item-text="name"
                      item-value="pk"
                      v-model="newAnnotation.category"
                      :label="c$t('categoryLabel')"
                      :rules="[v => !!v || $t('mandatory')]"
                      required
                    >
                      <template slot="item" slot-scope="data">
                        <img
                          :src="assetBaseUrl + '/media/' + data.item.icon"
                          height="24px"
                        /><p>{{data.item.name}}</p>
                      </template>
                    </v-select>
                    <v-select
                      v-if="statesList.length > 0"
                      :items="statesList"
                      item-text="name"
                      item-value="pk"
                      v-model="newAnnotation.state"
                      :label="c$t('stateLabel')"
                      :rules="[v => !!v || $t('mandatory')]"
                      required
                    ></v-select>
                    <v-text-field
                      v-model="newAnnotation.title"
                      :label="c$t(annotationKindKey[newAnnotation.kind] + '.title')"
                      :rules="[v => !!v || $t('mandatory')]"
                      required
                    />
                    <v-text-field
                      v-if="newAnnotation.kind === 'OBJ'"
                      v-model="newAnnotation.subtitle"
                      :label="c$t(annotationKindKey[newAnnotation.kind] + '.subtitle')"
                      :rules="[v => !!v || $t('mandatory')]"
                      required
                    />
                    <v-container
                      v-if="newAnnotation.kind === 'OBJ'"
                      class="pa-0"
                    >
                      <v-row>
                        <v-col>
                          <v-select
                            :items="constVals"
                            v-model="newAnnotation.constructionYear"
                            :label="
                              c$t(annotationKindKey[newAnnotation.kind] + '.constructionYear')
                            "
                          ></v-select>
                          <!-- v-text-field
                            type="number"
                            v-model="newAnnotation.constructionYear"
                            :label="
                              c$t(annotationKindKey[newAnnotation.kind] + '.constructionYear')
                            "
                          / -->
                        </v-col>
                        <v-col>
                          <v-select
                            :items="demoVals"
                            v-model="newAnnotation.demolitionYear"
                            :label="
                              c$t(annotationKindKey[newAnnotation.kind] + '.demolitionYear')
                            "
                          ></v-select>
                          <!-- v-text-field
                            type="number"
                            v-model="newAnnotation.demolitionYear"
                            :label="
                              c$t(annotationKindKey[newAnnotation.kind] + '.demolitionYear')
                            "
                          / -->
                        </v-col>
                      </v-row>
                    </v-container>

                    <div v-if="newAnnotation.kind !== 'PLY'">
                      <v-file-input
                        accept=".png,.jpg,.jpeg"
                        capture="user"
                        multiple
                        :label="$t('files')"
                        truncate-length="20"
                        v-model="uploadFiles"
                        @change="uploadAnnotationAttachments"
                      >
                      </v-file-input>
                      <p class="small">{{ $t('filesHint')}}</p>
                    </div>

                    <v-textarea
                      outlined
                      rows="4"
                      v-model="newAnnotation.text"
                      :label="c$t(annotationKindKey[newAnnotation.kind] + '.text')"
                      :rules="newAnnotation.kind === 'OBJ' ? [] : [v => !!v || $t('mandatory')]"
                      :required="newAnnotation.kind === 'OBJ' ? null : true"
                    />
                    <v-textarea
                      v-if="newAnnotation.kind === 'OBJ'"
                      outlined
                      rows="4"
                      v-model="newAnnotation.moreinfo"
                      :label="c$t(annotationKindKey[newAnnotation.kind] + '.moreinfo')"
                    />
                    <v-textarea
                      v-if="newAnnotation.kind === 'OBJ'"
                      outlined
                      rows="4"
                      v-model="newAnnotation.comment"
                      :label="c$t(annotationKindKey[newAnnotation.kind] + '.comment')"
                    />

                    <div class="d-flex justify-space-between">
                      <v-btn
                      @click="cancelAnnotation">
                        {{ $t('cancel') }}
                      </v-btn>
                      <v-btn
                        color="primary"
                        @click="validateStepOne"
                      >
                        {{ $t('next') }}
                      </v-btn>
                    </div>
                  </v-stepper-content>
                  <v-stepper-content
                    step="2"
                    class="pa-0">
                    <p>{{ c$t(annotationKindKey[newAnnotation.kind] +'.emailhint') }}</p>
                    <v-text-field
                      v-model="newAnnotation.email"
                      :label="$t('email')"
                      :rules="[
                        v => !!v || $t('mandatory'),
                        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(v) || $t('inv')]"
                      required
                    />
                    <v-select
                      v-if="annotations.usergroups.length > 0"
                      :items="annotations.usergroups"
                      item-text="name"
                      item-value="key"
                      v-model="newAnnotation.usergroup"
                      label="Personengruppe"
                      :rules="[v => !!v || $t('mandatory')]"
                      required
                    ></v-select>
                    <p class="small">{{ $t('notpublic')}}</p>
                    <div class="d-flex justify-space-between">
                      <v-btn
                      @click="commentstepper = 1">
                        {{ $t('prev') }}
                      </v-btn>
                      <v-btn
                        type="submit"
                        color="primary"
                      >
                        {{ $t('save') }}
                      </v-btn>
                    </div>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-form>
          </v-card>
        </div>
      </v-scale-transition>

      <v-dialog
        v-model="dialog"
        :hide-overlay="true"
        width="320"
      >
        <v-card>
          <v-card-title class="smalltitle">
            {{ dialogcontent.title }}
          </v-card-title>

          <v-card-text>
            {{ dialogcontent.text }}
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="dialog = false"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <div id="polygonstatisticsholder" v-if="statisticPanelOpen">
        <div v-for="(statistic, key) in spatialData" :key="key">
          <h2>{{ queriesData[key].title }}</h2>
          <div>
            <h3>Polygon</h3>
            <div class="progress-container">
              <div class="progress-indicator"
                :style="{ width: statistic.polygon.progress + '%'
                  }">
                {{ statistic.polygon.label }}</div>
            </div>
          </div>
          <!-- <div>
            <h3>Quartier</h3>
            <div class="progress-container">
              <div class="progress-indicator"
                :style="{ width:
                  statistic.neighbourhood.progress + '%'
                  }">
                {{ statistic.neighbourhood.label }}</div>
            </div>
          </div> -->
          <div>
            <h3>Stadt</h3>
            <div class="progress-container">
              <div class="progress-indicator"
                :style="{ width:
                  statistic.all.progress + '%'
                  }">
                {{ statistic.all.label }}</div>
            </div>
          </div>
        </div>
      </div>

      <div id="commentholder">
        <div id="currentComment"
          :class="{
            withComments: withComments,
            maxW: currentCommentHasAttachements
          }"
        >
        <template v-if="currentComment">
          <b>{{currentComment.data.properties.title}}</b><br>
          <v-carousel
            v-if="currentComment.attachements.length > 0"
            height="auto"
            hide-delimiters
            class="my-1">
            <v-carousel-item
              v-for="(item,i) in currentComment.attachements"
              :key="i"
              :src="assetBaseUrl + '/media/' + item.document"
            ></v-carousel-item>
          </v-carousel>
          <div
            v-if="currentComment.kind == 'PLY'">
            Status:
            <span
              class="statusLabel"
              :style="{ 'background-color': currentComment.category.color }">
              {{currentComment.category.name}}
            </span><br>
            Fläche: ca. {{currentComment.data.properties.area}}
          </div>
          {{currentComment.data.properties.description}}<br>

          <div
            v-if="(annotations.marker.likes && currentComment.kind == 'COM') ||
              (annotations.object.likes && currentComment.kind == 'OBJ') ||
              (annotations.polygon.likes && currentComment.kind == 'PLY')"
            class="d-flex align-center justify-end primary--text">
            <p class="rating">
              <v-icon color="primary" small>mdi-heart-outline</v-icon>
              <b
                style="vertical-align: middle;"
              > {{currentComment.rating}}</b>
            </p>
            <v-btn
              fab x-small color="white"
              :disabled="ratingpause"
              class="primary--text"
              ref="rateupBtn"
              @click="rateUp(currentComment.pk)"
              ><v-icon small>mdi-heart-plus</v-icon></v-btn>
            <v-icon
              id="addHeart"
              v-if="ratingpause"
              small
              color="primary"
              :style="cssVars"
              >mdi-heart</v-icon>
          </div>
          <div v-if="currentComment.category.commentsEnabled">
            <h3>Kommentare</h3>
            <div id="commento"></div>
          </div>
        </template>
        </div>
      </div>

      <object-detail
        :object="currentObject"
        :enableLikes="annotations.object.likes"
        v-on:close="$router.push({ replace: true, params: { annoid: null } })"
      />
    </v-main>
</template>

<style>
html,
body,
#app .v-application--wrap,
#map {
  min-height: calc(100vh - var(--vh-offset, 0px));
  height: calc(100vh - var(--vh-offset, 0px));
}

#map {
  position: relative;
  width: 100%;
  overflow: hidden;
  background: #dedede
    linear-gradient(90deg, #dedede 0%, #f2f2f2 17%, #dedede 23%) repeat-y;
  background-size: 125% 10%;
  animation: BGani 2s ease infinite;
}
.addingAnnotation + #map {
  cursor: crosshair;
}

@keyframes BGani {
  0% {
    background-position: 110% 0%;
  }
  66% {
    background-position: -410% 0%;
  }
  100% {
    background-position: -410% 0%;
  }
}
#map.leaflet-container {
  background: #dedede;
  animation: none;
}
.leaflet-popup-content {
  padding: 10px;
}
.leaflet-popup-content img {
  max-width: 100%;
  min-width: 260px;
}

#mapinfo {
  position: absolute;
  bottom: 2.5em;
  right: 1.6em;
  min-width: 240px;
  clip-path: circle(0% at 95% 90%);
  transition: clip-path 0.3s ease-out;
  pointer-events: none;
  z-index: 500; /* must be above mapbox icons */
}

#mapinfo.open {
  pointer-events: auto;
  clip-path: circle(100% at center);
}

#buttons {
  position: absolute;
  top: 5.6em;
  right: 1.6em;
  transition: top 0.3s;
  transition-timing-function: ease-in-out;
}
#buttons > button {
  display: block;
  margin-top: 1em;
}

span.statusLabel {
  padding: 1px 4px;
  border: 1px solid #000;
  border-radius: 4px;
}

.navopen #buttons {
  top: 0.6em;
  transition-delay: 0.4s;
}

.addHint {
  position: absolute;
  top: -4px;
  left: 50%;
  width: 680px;
  margin-left: -340px;
  text-align: center;
  text-shadow: 0 0 2px #fff, 0 0 5px #fff;
  background: #ffffff;
  padding: 14px 1em 10px 1em;
  border-radius: 4px;
  font-size: 16px;
  line-height: 1.2em;
  z-index: 1001; /* above [+/-] map zoom button */
}

@media (max-width: 700px) {
  .addHint {
    width: 90%;
    left: 5%;
    margin-left: 0;
  }
}
#commentholder {
  position: absolute;
  display: none;
}
.commentanimation {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  height: 400px;
  max-width: 90vw;
  margin: -200px 0 0 -200px;
}
@media (max-width: 420px) {
  .commentanimation {
    width: 90vw;
    margin: -200px 0 0 -45vw;
  }
}
@keyframes fromcircle {
  0% {
    border-radius: 30em;
  }
  100% {
    border-radius: 0;
  }
}

.scale-transition-enter-active #commentedit {
  animation: fromcircle 0.3s ease-out;
  animation-fill-mode: both;
}
.scale-transition-leave-active #commentedit {
  animation: fromcircle reverse 0.2s ease-out;
  animation-fill-mode: both;
}

#commentedit {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 510;
  overflow: hidden;
  max-width: 90vw;
}
#commentform {
  max-height: calc(100vh - 100px);
  overflow: auto;
}

#currentComment {
  width: 200px;
  max-width: calc(90vw - 20px);
}
#currentComment.withComments {
  width: 380px !important;
  max-height: 40vh;
  overflow: auto;
}

#currentComment.maxW {
  width: calc(100vw - 40px);
  max-width: 430px;
}
.v-list-item img {
  margin-right: 1em;
}
p.rating {
  color: primary;
  font-size: 13px;
  margin-bottom: 0;
  padding-right: 1em;
  user-select: none;
}
#addHeart {
  position: absolute;
  right: 1.2em;
  animation: addHeart 1.2s 0.4s ease-in-out both;
}
@keyframes addHeart {
  0% {
    right: 1.2em;
    scale: 0.6;
    opacity: 0;
  }
  13% {
    opacity: 1;
    scale: 2;
  }
  25% {
    scale: 1.6;
    right: 1.2em;
  }
  70% {
    scale: 1.6;
    right: var(--endpos);
    opacity: 1;
  }
  95% {
    scale: 0.3;
    right: var(--endpos);
  }
  100% {
    opacity: 0;
  }
}

.smalltitle {
  font-size: 16px !important;
}

.leaflet-control-attribution.leaflet-compact-attribution::after {
  content: none;
  display: none;
}
.leaflet-container .leaflet-control-attribution.leaflet-compact-attribution {
  margin: 0;
  visibility: visible;
  padding: 0;
  padding-right: 5px;
}

#polygonstatisticsholder {
  width: 320px;
  height: 96vh;
  z-index: 500;
  display: block;
  top: 15px;
  right: 15px;
  position: fixed;
  background: white;
  border-radius: 4px;
  padding: 10px 8px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  overflow-y: scroll;
}

#polygonstatisticsholder h2 {
  font-size: 2.2em;
  padding-top: 5px;
  padding-bottom: 5px;
}

#polygonstatisticsholder h3 {
  font-size: 1.5em;
}

.progress-container {
  width: 100%;
  max-width: 300px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.progress-indicator {
  background: black;
  border-radius: 10px;
  min-height: 20px;
  text-align: center;
  color: white;
  font-size: 12px;
  padding: 1px 0;
  min-width: 50px;
}

.leaflet-interactive {
  transition: opacity 0.3s;
}
</style>

<script>
import Vue from 'vue';
import SnapshotMeta from './SnapshotMeta.vue';
import ObjectDetail from './ObjectDetail.vue';
import MapLeaflet from './MapLeaflet.vue';
import MapMaplibre from './MapMaplibre.vue';

Vue.component('snapshot-meta', SnapshotMeta);
Vue.component('object-detail', ObjectDetail);
Vue.component('map-leaflet', MapLeaflet);
Vue.component('map-maplibre', MapMaplibre);

export default {
  data() {
    return {
      hash: this.$route.params.hash,
      wshash: this.$route.params.wshash,
      bfsNumber: this.$route.params.bfsNumber,
      assetBaseUrl: process.env.VUE_APP_ASSETBASEURL,
      mapinfoopen: true,
      addingAnnotation: null,
      newAnnotation: null,
      spatialData: {},
      statisticPanelOpen: false,
      tooltipContainer: null,
      timeout: null,
      commentstepper: 1,
      currentCommentIndex: null,
      currentObjectIndex: null,
      ratingpause: false,
      dialog: false,
      dialogcontent: {},
      title: '',
      description: '',
      legend: [],
      legendCategories: [],
      legendStates: [],
      sources: [],
      screenshotMode: this.$route.query.hasOwnProperty('screenshot'),
      screenshotIsThumbnail: this.$route.query.hasOwnProperty('thumbnail'),
      isMapLoaded: false,
      escListener: null,
      commentoUrl: process.env.VUE_APP_COMMENTO_URL || null,
      zoomIsUserModified: false,
      zoomStateModified: false,
      annotationKindKey: {
        COM: 'comment',
        PLY: 'polygon',
        OBJ: 'object'
      },
      uploadProgress: 0,
      uploadFiles: undefined,
      constVals: [
        this.$t('object.constVal.na'),
        this.$t('object.constVal.v1'),
        this.$t('object.constVal.v2'),
        this.$t('object.constVal.v3'),
        this.$t('object.constVal.v4'),
        this.$t('object.constVal.v5'),
        this.$t('object.constVal.v6'),
        this.$t('object.constVal.v7'),
        this.$t('object.constVal.v8'),
        this.$t('object.constVal.v9')
      ],
      demoVals: [
        this.$t('object.demoVal.na'),
        this.$t('object.demoVal.v1'),
        this.$t('object.demoVal.v2'),
        this.$t('object.demoVal.v3'),
        this.$t('object.demoVal.v4'),
        this.$t('object.demoVal.v5'),
        this.$t('object.demoVal.v6'),
        this.$t('object.demoVal.v7'),
        this.$t('object.demoVal.v8'),
        this.$t('object.demoVal.v9'),
        this.$t('object.demoVal.v10'),
        this.$t('object.demoVal.v11'),
        this.$t('object.demoVal.v12')
      ],
      disabledCatPks: [],
      disabledStatePks: []
    };
  },

  props: {
    snapshot: Object,
    annotations: Object,
    spatialDatasettes: Array,
    predecessor: Object
  },

  created() {
    this.escListener = document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        this.cancelAnnotation();
      }
    });
  },

  destroy() {
    document.removeEventListener(this.escListener);
  },

  // updated() {
  //   if (!this.currentObject) {
  //     this.$router.push({ name: 'workspace' });
  //   }
  // },

  computed: {
    legendWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '280px';
        default: return '320px';
      }
    },

    snapshotnav: {
      get() {
        return this.$store.state.snapshotnav;
      },
      set(val) {
        this.$store.commit('setSnapshotnav', val);
      }
    },
    currentComment() {
      if (this.annotations.items && this.currentCommentIndex !== null) {
        return this.annotations.items[this.currentCommentIndex];
      }
      return null;
    },
    currentObject() {
      if (this.$route.params.annoid) {
        return this.annotations.items.filter(
          a => a.pk === parseInt(this.$route.params.annoid, 10)
        ).pop();
      }
      return null;
    },

    withComments() {
      if (this.currentComment) {
        return this.currentComment.category.commentsEnabled;
      }
      return false;
    },

    cssVars() {
      let endpos = 4.1;
      if (this.currentComment.rating >= 10) {
        if (this.currentComment.rating >= 100) {
          if (this.currentComment.rating >= 1000) {
            endpos = 5.5;
          } else {
            endpos = 5.1;
          }
        } else {
          endpos = 4.6;
        }
      }
      return { '--endpos': `${endpos}em` };
    },

    categoriesList() {
      if (this.$store.state.isUserLoggedIn) {
        return this.annotations.categories;
      }
      return this.annotations.categories.filter(a => !a.hideInList);
    },

    statesList() {
      if (this.$store.state.isUserLoggedIn) {
        return this.annotations.states;
      }
      return this.annotations.states.filter(a => !a.hideInList);
    },

    queries() {
      return this.spatialDatasettes[0].queries.map(
        item => item.name
      );
    },

    queriesData() {
      const qd = {};
      this.spatialDatasettes[0].queries.forEach((i) => {
        qd[i.name] = { ...i };
      });
      return qd;
    },

    token() {
      return `${process.env.VUE_APP_SPATIALDATASETTE_TOKEN}` || null;
    },

    currentCommentHasAttachements() {
      if (this.currentComment) {
        if (this.currentComment.attachements.length > 0) {
          return true;
        }
      }
      return false;
    },
    filterClasses() {
      let classes = '';
      this.disabledCatPks.forEach((c) => {
        classes += `hide-c${c} `;
      });
      this.disabledStatePks.forEach((s) => {
        classes += `hide-s${s} `;
      });
      return classes;
    },
    filterCatStyle() {
      let styles = '';
      this.legendCategories.forEach((a) => {
        styles += `.hide-c${a.pk} .c${a.pk}{ opacity: 0; pointer-events: none; }`;
      });
      return `<style>${styles}</style>`;
    },
    filterStatStyle() {
      let styles = '';
      this.legendStates.forEach((a) => {
        styles += `.hide-s${a.pk} .s${a.pk}{ opacity: 0; pointer-events: none; }`;
      });
      return `<style>${styles}</style>`;
    },
    mapType() {
      if (this.snapshot && this.snapshot.views.length) {
        switch (this.snapshot.views[0].specType) {
          case 'gemeindescanSnapshot':
            return 'map-leaflet';
          case 'vega-lite':
          default:
            return 'map-maplibre';
        }
      }
      return 'map-maplibre';
    }
  },

  methods: {
    toggleCat(pk) {
      // console.log('toggleCat');
      // console.log(pk);
      if (this.disabledCatPks.includes(pk)) {
        this.disabledCatPks.splice(this.disabledCatPks.indexOf(pk), 1);
      } else {
        this.disabledCatPks.push(pk);
      }
    },
    toggleState(pk) {
      if (this.disabledStatePks.includes(pk)) {
        this.disabledStatePks.splice(this.disabledStatePks.indexOf(pk), 1);
      } else {
        this.disabledStatePks.push(pk);
      }
    },

    setupMeta() {
      this.title = this.snapshot.views[0].spec.title;
      this.description = this.snapshot.views[0].spec.description;
      this.legend = this.snapshot.views[0].spec.legend;

      if (this.annotations.categories) {
        const extraItems = this.annotations.categories
          .filter(c => !c.hideInLegend)
          .map((c) => {
            if (c.icon !== '') {
              return {
                pk: c.pk,
                svg: `${this.assetBaseUrl}/media/${c.icon}`,
                label: c.name,
                primary: !c.hideInList,
                hidden: false
              };
            }
            return {
              pk: c.pk,
              label: c.name,
              primary: !c.hideInList,
              hidden: false,
              shape: 'circle',
              size: 1.0,
              fillColor: c.color,
              fillOpacity: 0.4,
              strokeColor: c.color,
              strokeOpacity: 0.9,
              strokeWidth: 2
            };
          });
        this.legendCategories = [...extraItems];
      }
      if (this.annotations.states) {
        const extraItems = this.annotations.states
          .filter(c => !c.hideInLegend)
          .map((c) => {
            if (c.icon) {
              return {
                pk: c.pk,
                svg: `${this.assetBaseUrl}/media/${c.icon}`,
                label: c.name,
                primary: !c.hideInList,
                hidden: false
              };
            }
            return {
              pk: c.pk,
              label: c.name,
              primary: !c.hideInList,
              hidden: false
            };
          });
        this.legendStates = [...extraItems];
      }
      this.sources = this.snapshot.sources;
    },

    cancelAnnotation() {
      if (this.newAnnotation && this.newAnnotation.marker) {
        // TODO: move to map plugin
        // this.newAnnotation.marker.removeFrom(this.$refs.map.map);
        this.$refs.map.cancelAddAnnotation();
        this.newAnnotation = null;
      }
      this.$refs.map.polygonString = [];
      this.$refs.map.drawnItems.clearLayers();
      this.addingAnnotation = null;
      this.uploadFiles = null;
      // this.$refs.map.cancelAnnotation();
    },

    setupMap() {
      this.$refs.map.mapInit();
    },

    newComment(e) {
      this.addingAnnotation = null;
      this.commentstepper = 1;
      this.newAnnotation = {
        kind: 'COM',
        title: '',
        text: '',
        marker: e
      };
    },

    newPolygon(e) {
      this.addingAnnotation = null;
      this.commentstepper = 1;
      this.newAnnotation = {
        kind: 'PLY',
        title: '',
        text: '',
        marker: e
      };
    },

    newObject(e) {
      this.addingAnnotation = null;
      this.commentstepper = 1;
      this.newAnnotation = {
        kind: 'OBJ',
        title: '',
        subtitle: '',
        constructionYear: '',
        demolitionYear: '',
        text: '',
        text2: '',
        marker: e
      };
    },

    mapMovestart() {
      this.zoomIsUserModified = true;
    },

    mapZoomed(event) {
      if (this.zoomIsUserModified && event.target) {
        this.$store.commit('setMapZoomLevel', event.target.getZoom());
        this.$store.commit('setMapCenter', Object.values(event.target.getCenter()));
        this.zoomStateModified = true;
      }
    },

    mapMoveend(event) {
      if (this.zoomIsUserModified && event.target) {
        let newCenter = Object.values(event.target.getCenter());
        let newZoom = event.target.getZoom();
        if (this.mapType === 'map-maplibre') {
          newCenter = [newCenter[1], newCenter[0]];
          newZoom = newZoom < 20 ? newZoom + 1 : newZoom;
        }
        this.$store.commit('setMapCenter', newCenter);
        this.$store.commit('setMapZoomLevel', newZoom);
        this.zoomStateModified = true;
      }
    },

    setZoomStateModified(newState) {
      this.zoomStateModified = newState;
    },

    resetZoom() {
      this.$refs.map.resetZoom();
      this.zoomIsUserModified = false;
      this.$store.commit('setMapZoomLevel', null);
      this.$store.commit('setMapCenter', null);
      this.zoomStateModified = false;
    },

    currentCommentChanged(newCommentIndex) {
      this.currentCommentIndex = newCommentIndex;
      window.setTimeout(() => {
        if (this.commentoUrl !== null && this.currentComment
        && this.currentComment.category.commentsEnabled) {
          console.log(window); // eslint-disable-line no-console
          console.log(window.commento); // eslint-disable-line no-console
          console.log(window.commento.main); // eslint-disable-line no-console
          if (typeof window !== 'undefined' && window.commento.main === undefined) {
            const commentoScript = document.createElement('script');
            commentoScript.setAttribute('src', `${this.commentoUrl}/js/commento.js`);
            commentoScript.setAttribute('data-auto-init', false);
            commentoScript.setAttribute('data-page-id', `/${this.wshash}/comment/${this.currentComment.pk}`);
            commentoScript.setAttribute('defer', true);
            document.head.appendChild(commentoScript);
            window.setTimeout(() => {
              window.commento.main();
            }, 100);
          } else if (typeof window !== 'undefined' && window.commento) {
            window.commento.reInit({
              pageId: `${this.currentComment.pk}-${this.currentComment.id}`
            });
          }
        }
      }, 100);
    },

    validateStepOne() {
      const myform = this.$refs.commentform;
      if (myform.inputs[0].validate()) {
        if (myform.inputs[1].validate()) {
          if (myform.inputs[2].validate()) {
            this.commentstepper = 2;
          } else {
            myform.inputs[2].focus();
          }
        } else {
          myform.inputs[1].focus();
        }
      } else {
        myform.inputs[0].focus();
      }
    },

    async saveAnnotation() {
      const myform = this.$refs.commentform;
      if (!myform.validate()) {
        return false;
      }
      const csrftoken = this.$cookies.get('csrftoken', '');
      const formData = new FormData();
      let data = {};

      formData.append('kind', this.newAnnotation.kind);
      formData.append('workspace', this.wshash);

      switch (this.newAnnotation.kind) {
        case 'COM': {
          let latlng = { lng: 0, lat: 0 };
          if (this.mapType === 'map-maplibre') {
            latlng = this.newAnnotation.marker.getLngLat();
          } else {
            latlng = this.newAnnotation.marker.getLatLng();
          }
          data = {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [latlng.lng, latlng.lat]
            },
            properties: {
              fill: true,
              title: this.newAnnotation.title,
              description: this.newAnnotation.text
            }
          };

          break;
        }
        case 'OBJ': {
          let latlng = { lng: 0, lat: 0 };
          if (this.mapType === 'map-maplibre') {
            latlng = this.newAnnotation.marker.getLngLat();
          } else {
            latlng = this.newAnnotation.marker.getLatLng();
          }
          data = {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [latlng.lng, latlng.lat]
            },
            properties: {
              fill: true,
              title: this.newAnnotation.title,
              subtitle: this.newAnnotation.subtitle,
              constructionYear: this.newAnnotation.constructionYear,
              demolitionYear: this.newAnnotation.demolitionYear,
              description: this.newAnnotation.text,
              moreinfo: this.newAnnotation.moreinfo,
              comment: this.newAnnotation.comment
            }
          };
          break;
        }
        case 'PLY': {
          data = {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [[
                ...this.newAnnotation.marker.getLatLngs().map(
                  latlng => [latlng.lng, latlng.lat]
                )
              ]]
            },
            properties: {
              title: this.newAnnotation.title,
              description: this.newAnnotation.text
            }
          };
          break;
        }
        default: {
          console.log('Saving this annotation type not supported'); // eslint-disable-line no-console
          return false;
        }
      }

      formData.append('category', this.newAnnotation.category);
      if (this.statesList.length > 0) {
        formData.append('state', this.newAnnotation.state);
      }
      formData.append('author_email', this.newAnnotation.email);

      if (this.annotations.usergroups.length > 0) {
        formData.append('usergroup', this.newAnnotation.usergroup);
      }
      formData.append('data', JSON.stringify(data));

      try {
        const response = await this.$restApi.post('annotations/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-CSRFToken': csrftoken
          }
        });
        if (response.status === 201) {
          this.$refs.map.lockAnnotation();
          if (this.uploadFiles && this.uploadFiles.length > 0) {
            // a file is selected an actual file, upload it
            // this.status = this.$t('status.sendingFile');
            await this.httpUpload(this.uploadFiles, response.data.id);
          }

          const marker = this.newAnnotation.marker;
          marker.off();
          this.$refs.map.markSaved(this.$t('saved'));

          let labelPath = '';
          if (this.newAnnotation.kind === 'COM') {
            labelPath = 'comment';
          } else if (this.newAnnotation.kind === 'PLY') {
            labelPath = 'polygon';
          } else if (this.newAnnotation.kind === 'OBJ') {
            labelPath = 'object';
          }

          this.newAnnotation = null;

          this.dialogcontent = {
            title: this.$t('saved'),
            text: this.c$t(`${labelPath}.saved`)
          };
          this.dialog = true;
        }
        // } else {
        //   console.log('save');
        //   console.log(save);
        //   console.log('Speichern fehlgeschlagen');
        // }
      } catch (error) {
        console.log(error); // eslint-disable-line no-console
        this.newAnnotation = null;
        this.dialogcontent = {
          title: this.$t('failed'),
          text: this.$t('failedText')
        };
        this.dialog = true;
      }
      return true;
    },

    async rateUp(annotationPk) {
      this.ratingpause = true;

      if (this.annotations.polygon.likes || this.annotations.marker.likes) {
        const csrftoken = this.$cookies.get('csrftoken', '');
        const formData = new FormData();

        const save = await this.$restApi.patch(`rateupannotation/${annotationPk}/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-CSRFToken': csrftoken
          }
        });
        if (save.status === 200) {
          window.setTimeout(() => {
            this.currentComment.rating = parseInt(save.data.rating, 10);
          }, 1400);
        } else {
          console.log('Speichern fehlgeschlagen'); // eslint-disable-line no-console
        }

        this.$refs.rateupBtn.$el.blur();
        window.setTimeout(() => { this.ratingpause = false; }, 1800);
      }
    },

    fetchPolygonStats(db, query, polygon, neighbourhood, scope) {
      if (scope === 'all') {
        polygon = '';
        neighbourhood = '';
      } else if (scope === 'neighbourhood') {
        polygon = '';
      } else {
        neighbourhood = '';
      }
      return fetch(`${db.baseUrl}/${query}.json?_shape=objects&polygon=${polygon}&neighbourhood=${neighbourhood}`, {
        method: 'get',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${this.token}`
        }
      })
        .then((res) => {
          // a non-200 response code
          if (!res.ok) {
            // create error instance with HTTP status text
            const error = new Error(res.statusText);
            error.json = res.json();
            throw error;
          }
          return res.json();
        })
        .then((json) => {
          // set the response data
          this.spatialData[query][scope] = json.rows[0];
          this.spatialData[query][scope].progress = Math.round(
            this.spatialData[query][scope][
              this.queriesData[
                query
              ].summary_stat
            ] / this.queriesData[query].summary_compare * 100
          );
          this.spatialData[query][scope].label = Math.round(
            this.spatialData[query][scope][
              this.queriesData[
                query
              ].summary_stat
            ] * 100
          ) / 100;
        })
        .catch((err) => {
          // error.value = err;
          if (err.json) {
            return err.json; // .then((json) => {
            // error.value.message = json.message;
            // });
          }
          return null;
        });
    },

    resetSpatialData() {
      if (this.spatialDatasettes.length > 0) {
        const facets = { all: {}, neighbourhood: {}, polygon: {} };
        // eslint-disable-next-line no-return-assign
        const filled = {};
        this.queries.forEach((q) => {
          filled[q] = { ...facets };
        });
        this.spatialData = filled;
      }
    },

    c$t(path) {
      return this.annotations.mode ? this.$t(`${this.annotations.mode}.${path}`) : this.$t(path);
    },

    uploadAnnotationAttachments() {
      this.uploadProgress = 0;
    },

    async httpUpload(files, annotationPk) {
      const csrftoken = this.$cookies.get('csrftoken', '');
      const formData = new FormData();

      files.forEach((file, i) => {
        formData.append(document + i, file);
      });

      await this.$restApi.patch(`annotations/${annotationPk}/attachments/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-CSRFToken': csrftoken
        },
        onUploadProgress: (event) => {
          this.uploadProgress = Math.floor(100 * event.loaded / event.total);
        }
      });
    }
  },

  watch: {
    spatialDatasettes() {
      this.resetSpatialData();
    }
  }
};
</script>
